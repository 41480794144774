
        @use "sass:math";
        @use "variables" as *;
      
.section {
  text-align: center;
}

.basketRedesignImageWrapper {
  margin: -20px -20px 0;

  @media (min-width: 768px) {
    margin: -24px -32px 0;
  }
}

.basketRedesignImageContainer {
  width: 100%;
  box-sizing: border-box;

  &.homechef {
    background-color: $cream-100;
  }

  &.tempo {
    background-color: $tempo-blue-10;
  }
}

.basketRedesignContent {
  padding: 0 20px;

  @media (min-width: 768px) {
    padding: 0 32px;
  }
}

.basketRedesignImg {
  width: 100%;
  margin: auto;

  @media screen and (min-width: $bp2) {
    width: 100%;
    max-width: 450px;
  }

  &.desktop {
    display: none;
    visibility: hidden;

    @media screen and (min-width: $bp2) {
      visibility: visible;
      display: block;
    }
  }

  &.mobile {
    width: 100%;
    visibility: visible;
    display: block;

    @media screen and (min-width: $bp2) {
      visibility: hidden;
      display: none;
    }
  }
}

.img {
  width: 40vw;
  margin: auto;

  @media screen and (min-width: $bp2) {
    width: 90vw;
    max-width: 450px;
  }

  &.desktop {
    display: none;
    visibility: hidden;

    @media screen and (min-width: $bp2) {
      visibility: visible;
      display: block;
    }
  }

  &.mobile {
    width: 65%;
    visibility: visible;
    display: block;

    @media screen and (min-width: $bp2) {
      visibility: hidden;
      display: none;
    }
  }
}

div.container {
  min-height: 600px;

  & > div {
    overflow-x: hidden;

    max-height: 98.5vh;
  }
}

.border {
  background-color: $charcoal-60;
  width: calc(100% + #{$spacingXl * 2});
  height: 1px;
  margin-left: -$spacingXl;
}
