
        @use "sass:math";
        @use "variables" as *;
      
.banner {
  color: $white-100;
  align-items: center;

  @media screen and (max-width: $containerWidth) {
    width: 100%;
  }

  @media screen and (max-width: $bp2) {
    flex-direction: column;
    align-items: normal;
    height: auto;
    padding: 10px;
  }
}

.success {
  background-color: $green-100;

  &--tempo {
    background-color: $tempo-blue-90;
  }
}

.textContainer {
  justify-content: center;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: $bp2) {
    margin-bottom: $spacingMd;
  }
}

.heading {
  @media screen and (max-width: $bp2) {
    text-align: left;
  }
}

.description {
  font-size: 16px;
  line-height: 20px;
  text-align: left;

  @media screen and (max-width: $bp2) {
    font-size: 16px;
    line-height: 24px;
    text-align: left;
  }
}

.textAndImage {
  @media screen and (max-width: $bp2) {
    margin: 0;
    padding: 0;
  }
}

.tacoImage {
  max-width: 12rem;

  @media screen and (max-width: $bp2) {
    display: none;
  }
}

.buttonReactivate {
  @media screen and (max-width: $bp2) {
    text-align: center;
    display: inline-block;
    width: 350px;
    height: 48px;
  }
}
