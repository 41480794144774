
        @use "sass:math";
        @use "variables" as *;
      
.circle {
  position: absolute;
  border-radius: 50%;
  text-align: center;
  height: 17px;
  width: 17px;
  background: $orange-100;
  color: $white-100;
  font-size: 10px;
  font-weight: 700;
  line-height: 150%;
  top: 1.55rem;
  right: 1.50rem;
  bottom: 1.50rem;
  left: 1.75rem;
  border: 1px solid #FFF;

  @media screen and (max-width: $bp1) {
    top: 1.25rem;
    right: 1.25rem;
    bottom: 1.25rem;
  }
}

button.iconPadding {
  padding-right: 20px;
  padding-left: 8px;
}
