
        @use "sass:math";
        @use "variables" as *;
      
.innerCarousel {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  left: 0;
  justify-content: space-between;
  position: relative;
  transition: all 500ms ease 0s;
}

.wrapper {
  height: 100%;
}

.carouselContainer {
  height: 100%;
}

.container {
  height: 100%;
  flex-direction: column;
  margin: none;
  @media screen and (min-width: $bp1) {
    display: flex;
    flex-direction: row-reverse;
  }
}

.promo {
  overflow: hidden;
  width: 86%;
  max-height: none;
  margin: 0 0px 0 16px;
  flex-shrink: 0;
  background-color: $white-100;
  border-radius: $radiusSm;
  flex-direction: row;

  @media screen and (min-width: $bp1) {
    width: 100%;
    min-height: 220px;
    margin: 0;
  }
}

.singlePromo {
  width: 100%;
  margin: 0;
}

.content {
  user-select: none;
  flex: 62%;
  padding: $spacingMd $spacingLg;
  @media screen and (min-width: $bp1) {
    user-select: auto;
    padding: $spacingSm;
  }
}

.photoWrapper {
  width: 100%;
  height: 100%;
  margin-left: 1rem;
  margin-right: 1rem;

  @media screen and (min-width: $bp1) {
    margin-right: 0;
  }
}

.step {
  transition: all 500ms ease 0s;
}

.step0 {
  transform: translateX(0);
}

.step1 {
  transform: translateX(calc(-86% - 16px));
  @media screen and (min-width: $bp1) {
    transform: translateX(-100%);
  }
}

.step2 {
  transform: translateX(calc(-172% - 16px));
  @media screen and (min-width: $bp1) {
    transform: translateX(-200%);
  }
}

.photo {
  user-select: none;
  pointer-events: none;
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 50%;
  background-size: cover;
  height: 144px;
  border-radius: $radiusSm $radiusSm 0 0;

  @media screen and (min-width: $bp1) {
    height: 100%;
    border-radius: 0 $radiusSm $radiusSm 0;
  }
}

.photosContainer {
  flex: 38%;
}

.dots {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}

.instertitialContainer {
  background-color: $white-100;
  border-radius: $radiusSm;
}
