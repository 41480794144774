
        @use "sass:math";
        @use "variables" as *;
      
.error {
  background-color: $red-100;
}

.success {
  background-color: $green-100;

  &--tempo {
    background-color: $tempo-blue-90;
  }
}

.banner {
  color: $white-100;

  max-width: $containerWidth;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;

  @media screen and (max-width: $containerWidth) {
    width: 100%;
  }

  @media screen and (max-width: $bp1) {
    flex-direction: column;
    align-items: normal;
  }
}

.textContainer {
  @media screen and (max-width: $bp1) {
    margin-bottom: $spacingSm;
  }
}

.heading {
  font-size: 1.1em;
}

.description {
  font-size: 0.95em;
}

.heading,
.description {
  text-align: left;
}
