
        @use "sass:math";
        @use "variables" as *;
      
.promoNavButtons {
  height: 2em;
  width: 2em;

  position: absolute;
  top: calc(50% - 2.5em);

  border-radius: $radiusCircle;
  background-color: $white-100;
  border-width: 0;
  margin: 0;
  padding: 0;

  &:hover {
    background-color: $charcoal-60;
  }
}

.next {
  right: -1.2em;

  @media screen and (max-width: $bp3) {
    right: $spacingSm;
  }
}

.previous {
  left: -1.2em;

  @media screen and (max-width: $bp3) {
    left: $spacingSm;
  }
}
