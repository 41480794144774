
        @use "sass:math";
        @use "variables" as *;
      
.drawerMessage {
  color: $charcoal-100;
  display: grid;
  grid-template-columns: 10% 90%;
  padding: 1em 2em;

  @media screen and (max-width: $bp1) {
    grid-column-gap: $spacingXs;
  }

  &:focus,
  &:hover {
    color: $charcoal-100;
  }
}
