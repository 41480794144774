
        @use "sass:math";
        @use "variables" as *;
      
.container {
  position: relative;
  margin: $spacingMd auto $spacingLg;
}

.grid {
  background-color: $white-100;
  border-radius: $radiusSm;
  padding: $spacingSm;
  display: flex;
  flex-direction: column;
  display: grid;

  @media screen and (min-width: $bp2) {
    flex-direction: row;
    grid-template-columns: fit-content(20%) minmax(80%, auto);
    grid-template-areas: 'photo content';
  }
}

.content {
  padding: $spacingSm;
  @media screen and (min-width: $bp2) {
    grid-area: content;
  }
}

.photosContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: $spacingMd;
  padding-bottom: $spacingMd;

  @media screen and (min-width: $bp2) {
    flex-basis: 20%;
    grid-area: photo;
    padding-top: 0;
    padding-bottom: 0;
    padding: 0 1rem;
  }
}

.photo {
  flex: 1 1 100px;
  max-width: 96px;
}
